import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-primary",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline2",
			"color": "--light",
			"text-align": "center",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				Зв'ядіться з нами
			</>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:blank?info@kexonovel.com",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "Електронна пошта: info@kexonovel.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:+38 068 681 6831",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "Телефон: +38 068 681 6831"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Місцезнаходження:  пр. Миру 71/2, м. Хмельницький"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;